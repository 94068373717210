import React from "react";

const Arrow = (color, ) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3 25.5H8V22.5H34.3L21.9 10.1L24 8L40 24L24 40L21.9 37.9L34.3 25.5Z"
        fill={color.color}
      />
    </svg>
  );
};

export default Arrow;
