import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Arrow from "../../static/icons/arrow";

const useStyles = makeStyles({
  root: {
		background: "#161832",
    display: "flex",
    alignItems: "center",
    padding: "0 10% 10%",
		height: "30vh",
		gap: 30,
		flexDirection: "column",
    justifyContent: "center",
  },
	title:{
		color:"#fff",
		fontFamily: "Poppins Bold",
		fontSize: 40,
		fontWeight: 700,
		margin: "0 auto",
		textAlign: "center",
		"& span": {
      color: "#E9862B",
      fontStyle: "italic",
    },
	},
	contactBtn: {
    padding: "4px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 23,
    border: "1px solid  #fff",
    textDecoration: "none",
    textTransform: "initial",
		color: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
    "& svg": {
      height: 25,
      width: 40,
      verticalAlign: "sub",
    },
  },
  "@media (max-width: 1020px)": {
    root: {
      padding: "0px 5% 80px 5%",
    },
    title: {
      fontSize: 32,
    },
  }
});

const Question = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id="question">
      <h2 className={classes.title}>
        Do you have any
        <span>{' question '}</span>?
      </h2>
      <Button  href="mailto:contact@i-week.fr" className={classes.contactBtn}>
        Contact us
        <Arrow color="#fff"/>
      </Button>
    </section>
  );
};

export default Question;
