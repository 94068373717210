import React from "react";
import { makeStyles } from "@material-ui/core";
import IweekBanner from "../../static/icons/iweek.svg";
import FriendEffeil from "../../static/icons/friend_eiffel.png";
import FriendVersailles from "../../static/icons/friend_versailles.png";

const useStyles = makeStyles({
  root: {
    background: "#161832",
    height: "100vh",
  },
  iweek: {
    height: "100%",
    backgroundImage: `url(${IweekBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
    position: "relative",
  },
  friendVersailles: {
    position: "absolute",
    top: "10%",
    right: "10%",
    width: 300,
  },
  friendEffeil: {
    position: "absolute",
    bottom: "10%",
    left: "10%",
    height: 250,
  },
  "@media (max-width: 1020px)": {
    friendVersailles: {
      width: 250,
    },
    friendEffeil: {
      height: 200,
    },
  },
  "@media (max-width: 750px)": {
    root: {
      height: "50vh",
    },
    friendVersailles: {
      display: "none",
    },
    friendEffeil: {
      display: "none",
    },
  },
});

const Iweek = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id="iweek">
      <div className={classes.iweek}>
        <img
          className={classes.friendEffeil}
          src={FriendEffeil}
          alt="friend eiffel"
        />
        <img
          className={classes.friendVersailles}
          src={FriendVersailles}
          alt="friend versailles"
        />
      </div>

      {/* <img src={LogoIweek} alt="logo iweek" className={classes.iweek} /> */}
    </section>
  );
};

export default Iweek;
