import React from "react";
import { Helmet } from "react-helmet";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import StandingRoosterPicto from "../../static/icons/standing_rooster.svg";
import Presentation from "../../components/presentation";
import Program from "../../components/program";
import Iweek from "../../components/iweek";
import Question from "../../components/question";
import Arrow from "../../static/icons/arrow.js";

const useStyles = makeStyles({
  root: {},
  mainContainer: {
    display: "flex",
    height: "calc(100vh - 40px)",
    padding: "0 10%",
  },
  roosterPicto: {
    width: 400,
    height: 400,
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    gap: 60,
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 15,
  },
  mainTitle: {
    textAlign: "left",
    color: "#161832",
    fontFamily: "Poppins Bold",
    fontSize: 40,
    fontWeight: 700,
    "& span": {
      color: "#E9862B",
      fontStyle: "italic",
    },
  },
  date: {
    color: "#161832",
    fontFamily: "Poppins Light",
    fontSize: 40,
    fontWeight: 700,
    "& svg": {
      margin: "auto",
      verticalAlign: "sub",
    },
    "& span": {
      color: "#E9862B",
    },
  },
  applyBtn: {
    padding: "4px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 23,
    border: "1px solid  #161832",
    textDecoration: "none",
    textTransform: "capitalize",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
    "& svg": {
      height: 25,
      width: 25,
      verticalAlign: "sub",
    },
  },
  "@media (max-width: 1020px)": {
    mainContainer: {
      padding: "0 5%",
    },
    roosterPicto: {
      width: 300,
      height: 300,
    },
    mainTitle: {
      fontSize: 36,
    },
    date: {
      fontSize: 32,
    },
  },
  "@media (max-width: 750px)": {
    gridItem: {
      flexDirection: "column",
      justifyContent: "center",
      padding: "0 5%",
      gap: 30,
    },
    roosterPicto: {
      width: 300,
      height: 300,
    },
    mainTitle: {
      fontSize: 32,
    },
    date: {
      fontSize: 28,
      "& svg": {
        width: 32,
        height: 32,
      },
    },
  },
});

const Home = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="home">
      <Helmet>
        <meta
          charSet="utf-8"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
      </Helmet>
      <section className={classes.mainContainer}>
        <Grid container>
          <Grid item className={classes.gridItem}>
            <img
              className={classes.roosterPicto}
              src={StandingRoosterPicto}
              alt="Standing Rooster"
            />
            <div className={classes.titleContainer}>
              <Typography variant="h1" className={classes.mainTitle}>
                {" Throughout the week, visit "}
                <span>Paris</span>
                {" and discover "}
                <span>French gastronomy</span>
              </Typography>
              <Typography className={classes.date}>
                {"March "}
                <span>9</span>
                <Arrow color="#000" colorHover="#fff"/>
                <span>17</span>
                {" , 2024"}
              </Typography>
              <Button
                rel="noopener noreferrer"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSeRGkZavQmIhzXNmF5QuDUv8mXk-RXThRyBItynGVyO3yy2rA/viewform"
                className={classes.applyBtn}
                onMouseOver={(e) => (e.currentTarget.querySelector("svg path").style.fill = "#fff")}
                onMouseLeave={(e) => (e.currentTarget.querySelector("svg path").style.fill = "#000")}
              >
                Apply
                <Arrow color="#000"/>
              </Button>
            </div>
          </Grid>
        </Grid>
      </section>

      <Presentation />
      <Program />
      <Iweek />
      <Question />
    </div>
  );
};

export default Home;
