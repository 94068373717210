import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Cercle from "../../static/icons/cercle.svg";
import Map from "../../static/icons/map.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0 70px 0px",
    padding: "0 10%",
  },
  infoText: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 20,
    "& h2": {
      margin: 0,
      fontFamily: "Poppins Bold",
      fontSize: 40,
      fontWeight: 700,
      color: "#161832",
    },
    "& h3": {
      margin: 0,
      fontFamily: "Poppins Regular",
      fontSize: 32,
      fontWeight: 400,
      color: "#E9862B",
    },
  },
  iweekCercle: {
    backgroundImage: `url(${Cercle})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  paragraph: {
    margin: 0,
    fontFamily: "Poppins Regular",
    fontSize: 16,
    fontWeight: 400,
    color: "#161832",
  },
  rectangle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 200,
    height: 200,
    borderRadius: 20,
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    height: "100%",
  },
  numberDatas: {
    position: "relative",
  },
  number: {
    fontFamily: "Poppins Bold",
    fontSize: 48,
    fontWeight: 700,
    color: "#fff",
  },
  titleRectangle: {
    fontFamily: "Poppins Regular",
    fontSize: 16,
    fontWeight: 400,
    color: "#fff",
  },
  blueRectangle: {
    height: 300,
    width: 300,
    backgroundColor: "#002E5C",
    backgroundImage: `url(${Map})`,
    backgroundSize: "cover",
    backgroundPosition: "center 25px",
  },
  redRectangle: {
    position: "absolute",
    top: "-70px",
    right: "-70px",
    height: 170,
    width: 170,
    backgroundColor: "#FF3B4A",
  },
  "@media (max-width: 1020px)": {
    root: {
      padding: "0 5%",
      flexDirection: "column",
      alignItems: "center",
    },
    infoText: {
      width: "100%",
      alignItems: "center",
      textAlign: "center",
      gap: 30,
      "& h2": {
        fontSize: 32,
        width: "100%",
      },
      "& h3": {
        fontSize: 24,
      },
      "& p": {
        fontSize: 14,
      },
    },
    numberContainer: {
      width: "100%",
      height: 300,
      justifyContent: "center",
    },
    numberDatas: {
      position: "relative",
      height: "fit-content",
    },
    number: {
      fontSize: 32,
    },
    titleRectangle: {
      fontSize: 14,
    },
    blueRectangle: {
      height: 200,
      width: 200,
    },
    redRectangle: {
      top: "-50px",
      right: "-50px",
      height: 100,
      width: 100,
    },
  },
});

const Presentation = () => {
  const classes = useStyles();

  return (
    <section className={classes.root} id="presentation">
      <div className={classes.infoText}>
        <div>
          <h2>
            What is the <span className={classes.iweekCercle}>iWeek</span>{" "}
            association?
          </h2>
          <h3>Efrei's association</h3>
        </div>

        <div className={classes.paragraph}>
          <p>
            The iWeek association welcomes students from partner schools to
            EFREI's International Day.
          </p>

          <p>
            The iWeek association's mission is to facilitate the discovery of
            Paris and promote cultural diversity by welcoming students from all
            over the world for a week in Paris.
          </p>
        </div>
      </div>
      <div className={classes.numberContainer}>
        <div className={classes.numberDatas}>
          <div className={`${classes.rectangle} ${classes.blueRectangle}`}>
            <Typography variant="span" className={classes.number}>
              96
            </Typography>
            <Typography variant="span" className={classes.titleRectangle}>
              universities
            </Typography>
          </div>

          <div className={`${classes.rectangle} ${classes.redRectangle}`}>
            <Typography variant="span" className={classes.number}>
              38
            </Typography>
            <Typography variant="span" className={classes.titleRectangle}>
              countries
            </Typography>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
