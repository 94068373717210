import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import EiffelTour from "../../static/icons/eiffel_tour.svg";
import Arrow1 from "../../static/icons/arrow1.svg";
import Arrow2 from "../../static/icons/arrow2.svg";
import Arrow3 from "../../static/icons/arrow3.svg";
import Arrow4 from "../../static/icons/arrow4.svg";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "30px 0 0 0",
    gap: 30,
    padding: "0 10%",
  },
  title: {
    textAlign: "center",
    color: "#161832",
    fontFamily: "Poppins Bold",
    fontSize: 40,
    fontWeight: 700,
    width: "50%",
    margin: "0 auto",
  },
  decor: {
    borderRadius: "600px 600px 0px 0px",
    background: "#161832",
    width: "100%",
    height: 933,
    margin: "0 auto",
  },
  decorEiffel: {
    height: "100%",
    backgroundImage: `url(${EiffelTour})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center 50px",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  containerProgram: {
    width: "40%",
    display: "flex",
    alignItems: "center",
  },
  divProgram: {
    width: "70%",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 23,
  },
  containerText: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  titleText: {
    color: "#E9862B",
    fontFamily: "Poppins Bold",
    fontSize: 28,
    fontWeight: 700,
  },
  infoText: {
    color: "#fff",
    fontFamily: "Poppins Regular",
    fontSize: 12,
    fontWeight: 400,
  },
  containerImg: {
    height: "100px",
    width: "80px",
    "& img": {
      height: "100px",
      width: "80px",
    },
  },
  iconWidth: {
    width: "60px!important",
  },
  firstContainerProgram: {
    paddingTop: 130,
  },
  left: {
    justifyContent: "flex-start",
    paddingRight: 365,
  },
  right: {
    justifyContent: "flex-end",
    paddingLeft: 330,
  },

  "@media (max-width: 1020px)": {
    root: {
      padding: "0 5%",
    },
    title: {
      width: "100%",
      fontSize: 32,
    },
    decor: {
      width: 700,
      height: 700,
    },
    decorEiffel: {
      gap: 20,
    },
    containerProgram: {
      width: "60%",
    },
    divProgram: {
      width: "50%",
      gap: 18,
    },
    titleText: {
      fontSize: 22,
    },
    infoText: {
      fontSize: 10,
    },
    containerImg: {
      height: 80,
      width: 60,
      "& img": {
        height: 80,
        width: 60,
      },
    },
    iconWidth: {
      width: "40px!important",
    },
    left: {
      paddingRight: 150,
    },
    right: {
      paddingLeft: 150,
    },
  },
  "@media (max-width: 750px)": {
    decor: {
      paddingTop: 20,
      width: "100%",
      height: 650,
    },
    decorEiffel: {
      backgroundImage: "none",
    },
    divProgram: {
      justifyContent: "center",
      width: "100%",
    },
    firstContainerProgram: {
      padding: 0,
    },
    containerText: {
      gap: 10,
      alignItems: "center",
    },
    containerImg: {
      display: "none",
    },
    infoText: {
      textAlign: "center!important",
    },
    left: {
      paddingRight: 0,
    },
    right: {
      paddingLeft: 0,
    },
    titleText: {
      textAlign: "center!important",
      fontSize: 18,
    },
  },
});

const Program = () => {
  const classes = useStyles();

  const program = [
    {
      title: "Monuments & museums",
      text: "During the days, the association will show you around Paris. You'll discover Paris' best-known monuments and visit famous museums.",
      arrow: Arrow1,
      position: "right",
    },
    {
      title: "French food",
      text: "Other associations at the school will introduce you to gastronomy.",
      arrow: Arrow2,
      position: "left",
    },
    {
      title: "Meet",
      text: "You'll meet students from all over the world who are doing the same thing as you.",
      arrow: Arrow3,
      position: "right",
    },
    {
      title: "Price",
      text: "Little price for little budget",
      arrow: Arrow4,
      position: "left",
    },
  ];

  return (
    <section className={classes.root} id="program">
      <h2 className={classes.title}>iWeek's program and conditions</h2>
      <div>
        <div className={classes.decor}>
          <div className={classes.decorEiffel}>
            {program.map((item, index) => {
              return (
                <div
                  key={index}
                  className={` 
                    ${index === 0 && classes.firstContainerProgram} 
                    ${classes.containerProgram}
                    ${item.position === "left" ? classes.left : classes.right}
                    `}
                >
                  <div
                    className={classes.divProgram}
                    style={{
                      flexDirection:
                        item.position === "left" ? "row" : "row-reverse",
                    }}
                  >
                    <div
                      className={classes.containerText}
                      style={{
                        textAlign: item.position === "left" ? "right" : "left",
                      }}
                    >
                      <Typography
                        className={classes.titleText}
                        style={{
                          textAlign:
                            item.position === "left" ? "right" : "left",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        className={classes.infoText}
                        style={{
                          textAlign:
                            item.position === "left" ? "right" : "left",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </div>
                    <div className={classes.containerImg}>
                      <img
                        src={item.arrow}
                        alt="arrow"
                        className={
                          (index === 0 || index === 3) && classes.iconWidth
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Program;
